.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#broadcasting {
  max-width:200px;
}
.graphic-contents [class^="col-"] .contents {
  background-color:#0581c9;
  min-height:100%;
  font-family: 'Roboto', sans-serif;
  color:#fff;
  font-weight:300;
  text-transform: uppercase;
}
.progress-bar-vertical {
  width: 40px;
  min-height: 200px;
  margin-left: 10px;
  float: left;
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: flex-end;
  -webkit-align-items: flex-end; /* Safari 7.0+ */
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
  background-color:#ee732b;
}
div.result-text {
  font-weight:300;
  font-size:14px;
  padding-right:5px;
}
div.result-current {
  font-weight:900;
  font-size:24px;
  padding-top:0;
  margin-top:0;
  line-height: 24px;
}
.title {
  font-weight:900;
}

#reaction-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  max-width: 68px;
  max-height: 68px;
  bottom: 68px;
}






.reaction {
  position: absolute;
  left: 0;
  opacity: 0;
  bottom:0;

}
.reaction-1{
  animation: fade-in-up 5s forwards;
}
.reaction-2{
  animation: fade-in-up 5s forwards;
  animation-delay: 2s;
}
.reaction-3{
  animation: fade-in-up 5s forwards;
  animation-delay: 5s;
}
.reaction-4{
  animation: fade-in-up 5s forwards;
  animation-delay: 7s;
}

.reaction-icon img {
  max-width: 68px;
}

.reaction-name {
  text-align: center;
  color:#ee732b;
}

.reaction-image-0 {
  bottom: 0;
}

.reaction-image-1 {
  bottom: 68px;
}

/* add more rules for other reactions if needed */

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    opacity: 1;
    transform: translateY(calc(-40vh + 20%));
  }
  100% {
    opacity: 0;
    transform: translateY(calc(-80vh + 20%));
  }
}


.page-questions {
  background-color:#fff;
}
.tile {
  background-color:#fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding:3px 5px;
  border-radius:5px;
}
.clickable {
  cursor:pointer;
}
#questions {
  padding-bottom:10px;
}
.thequestion {
  /*border-bottom:1px solid #282c34;*/
  padding:3px;
  width:100%;
}
.thequestion:hover {
  background-color:#ee732b;
  color:#fff;
  cursor:pointer;
}
.thequestion .number {
  font-weight:600;
  width:10%;
  float:left;
}
.thequestion .questionname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:90%;
  display:inline-block;
}
.thequestion .questiondate {
  font-style: italic;
}
.thequestion .questiondate svg {
  vertical-align: top;
  margin-top:3px;
  margin-right:10px;
}
.min-vh-75 {
  min-height: 75vh !important;
}

#broadcasting_nccc .wrapper {
  background-color: #22324e;
  background-image: url("/public/imgs/ticket-bg-blue.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color:#fff;
  font-family: sans-serif;
  padding:5px;
  font-size:30px;
}
#broadcasting_nccc h2 {
  text-align:center;
  font-weight:900;
  background-color:#76103f;
  padding:5px 0px 8px;
  margin-bottom:15px;
}
#broadcasting_nccc hr {
  margin-bottom:5px;
}
#broadcasting_nccc .percent {
  font-size:30px;
  font-weight:900;
  padding-top:8px;
}
#broadcasting_nccc .logoicon {
  height:55px;
}
#broadcasting_nccc .powerby {
  font-size:12px;
  color:#fff;
  padding-right:13px;
}
#broadcasting_nccc .powerby img {
  height:30px;
}
#broadcasting_nccc .dis {
  padding-top:8px;
}

#broadcasting_hori, #broadcasting_hori h1 {
  font-family: 'Roboto', sans-serif;
  font-weight:900;
  color:#fff;
}
#broadcasting_hori h1 {
  margin:0;
  padding:0;
  font-size:30px;
}
#broadcasting_hori h2 {
  padding-bottom:20px;
  font-size:16px;
  text-transform:uppercase;
}
#broadcasting_hori .wrapper {
  background-color:#282c34;
  padding:10px;
  border-radius:10px;
}
#broadcasting_hori hr {
  margin-top:10px;
}
#qdetails {
  position:relative;
}
#qdetails #actionbtns {
  position:absolute;
  bottom:20px;
  right:20px;
  width: 100%;
}

#nccc_2 {
  font-weight:900;
  text-transform: uppercase;
  color:#fff;
}
#nccc_2 .container {
  max-width: 100%;
}
#nccc_2 .row .col-6.left, #nccc_2 .row .col-6.right {
  background-color:#09264d;
}
#nccc_2 .row .col-6.left{
  border-top-left-radius: 25px;
  border-right:1px solid #fff;
}
#nccc_2 .row .col-6.right{
  border-top-right-radius: 25px;
  border-left:1px solid #fff;
}
#nccc_2 .team {
  text-align:center;
  font-size:2.5em;
  padding:15px 0 0 0;
}
#nccc_2 .percent {
  text-align:center;
  font-size:4em;
  padding-bottom:10px;
  min-height:106px;
}
#nccc_2 .powerby {
  background-color:#09264d;
  font-size:12px;
  color:#fff;
  padding:15px 0;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
#nccc_2 .powerby img {
  height:60px;
}
#nccc_2 .powerbar {
  background-color:#09264d;
  padding:10px 0px;
}
#nccc_2 .powerbar img {
  height:60px;
}
#nccc_2 .chart {
  max-height:200px;
}



#lccc_2 {
  font-weight:900;
  text-transform: uppercase;
  color:#fff;
}
#lccc_2 .container {
  max-width: 100%;
}
#lccc_2 .row .col-6.left, #lccc_2 .row .col-6.right {
  background-color:#cb212e;
}
#lccc_2 .row .col-6.left{
  border-top-left-radius: 25px;
  border-right:1px solid #fff;
}
#lccc_2 .row .col-6.right{
  border-top-right-radius: 25px;
  border-left:1px solid #fff;
}
#lccc_2 .team {
  text-align:center;
  font-size:2.5em;
  padding:15px 0 0 0;
}
#lccc_2 .percent {
  text-align:center;
  font-size:4em;
  padding-bottom:10px;
  min-height:106px;
}
#lccc_2 .powerby {
  background-color:#cb212e;
  font-size:12px;
  color:#fff;
  padding:15px 0;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
#lccc_2 .powerby img {
  height:60px;
}
#lccc_2 .powerbar {
  background-color:#cb212e;
  padding:10px 0px;
}
#lccc_2 .powerbar img {
  height:60px;
}
#lccc_2 .chart {
  max-height:200px;
}
.powerbar .row .col {
  margin:0 0 0 15px;
}
.powerbar .progress {
  height: 40px;
}

#minimal.runpredict .row .col-12#runsheader {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  line-height:3.5em;
  font-size:0.7em;
  padding-bottom:20px;
}
#minimal.runpredict .rightborder {
  border-right: 2px solid #fff;
}
#minimal.runpredict .leftborder {
  border-left: 2px solid #fff;
  border-right: 3px solid #000;
}
#minimal.runpredict .theRuns {
  font-size:6em;
}
#minimal.runpredict .subhead {
  padding-bottom:20px;
  font-size:2em;
}

#minimal {
  font-weight:900;
  text-transform: uppercase;
  color:#fff;
}
#minimal .container {
  max-width: 100%;
}
#minimal .row .col-6.left, #minimal .row .col-6.right, #minimal .row .col-5.left, #minimal .row .col-5.right {
  background-color:#cb212e;
}
#minimal .row .col-6.left, #minimal .row .col-5.left {
  border-top-left-radius: 25px;
  border-right:1px solid #fff;
}
#minimal .row .col-6.right, #minimal .row .col-5.right {
  border-top-right-radius: 25px;
  border-left:1px solid #fff;
}
#minimal .row .col-5.left, #minimal .row .col-5.right {
  border:none;
}
#minimal .team {
  text-align:center;
  font-size:2.5em;
  padding:15px 0 0 0;
}
#minimal .team.withdraw, #minimal .percent.withdraw {
  font-size:3.5em;
}
#minimal .draw {
  text-align:center;
  font-size:1.5em;
  padding:55px 0 0 0;
}
#minimal .drawpercent {
  text-align:center;
  font-size:1.5em;
  padding:0 0 0 0;
}
#minimal .percent {
  text-align:center;
  font-size:4em;
  padding-bottom:10px;
  min-height:106px;
}
#minimal .powerby {
  background-color:#fff;
  font-size:12px;
  color:#fff;
  border:3px solid #fff;
  padding:15px 0;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
#minimal .powerby img {
  height:60px;
}
#minimal .powerbar {
  background-color:#cb212e;
  padding:30px 0px;
}
#minimal .powerbar img {
  height:60px;
}
#minimal .chart {
  max-height:200px;
}
#minimal .calc {
  font-size:24px;
  position:relative;
  top:-6px;
  left:10px;
}
#minimal .c_ball {
  max-height:30px;
  animation: rotate 5s infinite linear;
}
.powerbar .row .col {
  margin:0 0 0 15px;
}
.powerbar .progress {
  height: 40px;
}
.powerbarAlt {
  height: 100px;
}
.powerby .row.ng {
  padding:0;
  margin:0;
}
.flagicon {
  margin: 0 20px 0 15px;
  border:1px solid #eaeaea;
  border-radius:5px;
}

.row.powerby {
  border:3px solid #76103f;
  padding:15px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color:#fff;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#compact .teams {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  background-color:#09264d;
  color:#fff;
  padding:3px 8px;
  margin-top:0;
}
#compact .batting {
  font-weight:900;
  font-size:18px;
  color:#000;
}

#footer, #header  {
  font-family: 'Roboto', sans-serif;
  font-weight:900;
  font-size:16px;
}
#footer span.teams {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 900;
  background-color:#09264d;
  color:#fff;
  padding:3px 8px;
}
#footer .batting {
  font-weight:900;
  font-size:24px;
}
#contents .row {
  border:3px solid #22324e;
  border-bottom:none;
  background-color:#fff;
  /*opacity:0.9;*/
}
#contents .row #wrapper {
  padding:20px 10px;
  min-height:690px;
}
#header .row {
  text-align:center;
  color:#fff;
  padding:5px 0px;
  font-size:36px;
  border:3px solid #22324e;
  border-bottom:none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #22324e;
}
.chartdisplay {
  max-height:100px;
}

#compact .teams {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  background-color:#09264d;
  color:#fff;
  padding:3px 8px;
  margin-top:0;
}
#compact .batting {
  font-weight:900;
  font-size:18px;
  color:#000;
}
#compact #header .row {
  font-size:unset;
  padding-right:25px;
  padding-left:25px;
}





#ic_fan {
  font-family: 'Roboto', sans-serif;
}
#ic_fan #header .row {
  background-color:#1d1d1b;
  border:none;
  border-radius:0;
}
#ic_fan #header .title {
  text-transform: capitalize;
  font-kerning: normal;
}
#ic_fan #header .matchname {
  font-size:18px;
  font-weight:normal;
}
#ic_fan #header .matchname:first-of-type {
  padding-top:10px;
}
#ic_fan #contents .row {
  border-color: #1d1d1b;
}
#ic_fan #contents .row #wrapper {
  padding:20px 10px;
  height:200px;
}
#ic_fan #contents .row #wrapper canvas {
  height:200px;
  width:50%;
} 
#ic_fan #footer .row {
  background-color:#1f1f1b;
  color:#fff;
  border:none;
  border-radius:0;
}
#ic_fan #footer .tweet {
  height:50px;
  cursor:pointer;
}
#ic_fan #footer .tweet:hover {
  color:#ee732b;
}
#ic_fan #footer .batting {
  min-height:36px;
}
#ic_fan #footer .spinner-border.text-light {
  margin-top:26px;
}
#ic_fan .fa-arrows-rotate {
  padding-top:30px;
  font-size:24px;
  cursor:pointer;
}
#ic_fan .fa-camera {
  padding-top:30px;
  font-size:24px;
  cursor:pointer;
  padding-left:15px;
}
#toolTipTable {
  font-size:8px;
  color:#00ff00;
}
#toolTipInteract {
  font-size:12px;
  font-weight:normal;
}
#toolTipInteract h3 {
  padding:0;
  margin:0;
  font-size:14px;
  font-weight:900;
}
#toolTipInteract .ballDel {
  background-color:#fff;
  border-radius:25px;
  padding:5px;
  margin-right:5px;
  color:#000;
  font-weight:bolder;
  width:35px;
  height:35px;
  padding-top:9px;
  text-align:center;
  float:left;
}
#toolTipInteract .ballDel.norun {
  background-color:#5a5a5a;
}
#toolTipInteract .ballDel.wicket {
  border-radius:0;
}
#toolTipInteract .icon {
  height:20px;
  float:left;
  margin-bottom:10px;
}
#toolTipInteract .player {
  padding:2px 0 0 5px;
  float:left;
}
#toolTipInteract .partner {
  padding:0px 0 0 25px;
  margin-top:-10px;
  color:#9a9a9a;
}
#toolTipInteract .currentBall {
  background-color:#f30707;
}


@media (max-width: 1080px) {
  #ic_fan #header .title {
    font-size:18px;
    line-height:16px !important;
    padding-top:10px;
  }
  #ic_fan #header .matchname {
    font-size:12px;
    padding-top:5px;
  }
  #ic_fan #footer .footbtns {
    margin:0;
    padding-bottom:10px;
  }
  #ic_fan #footer .competitors {
    text-align:center !important;
    margin-top:10px;
  }
}

@media (max-width: 767px) {
  #ic_fan #logo {
    max-width:100px;
  }
  #ic_fan #header .title {
    font-size:18px;
    text-align:center !important;
  }
  #ic_fan #header .matchname {
    text-align:center !important;
    font-size:11px;
  }
  #ic_fan #footer #toolTipInteract .col-3 {
    width:100%;

  }
}
.cricball svg{
  font-size:24px;
  animation: spin 3s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.arrow-animation {
  animation-duration: 0.5s; /* Adjust as needed */
  animation-fill-mode: forwards;
  display: inline-block; /* Or as needed */
}

#ttip .row {
  border: none;
}
#ttip .row.combo .holder {
  width:30px;
  float:left;
  padding:10px 0;
}
#ttip .row.combo .name{
  font-weight:300;
  font-size:26px;
  padding-top:5px;
  color:#000;
}
#ttip .icon {
  max-width:24px;
}
#ttip .ballDel {
  background-color:#000;
  border-radius:25px;
  padding:6px 5px 5px 5px;
  margin-right:5px;
  color:#fff;
  font-weight:bolder;
  width:35px;
  height:35px;
  text-align:center;
  float:left;
}
#ttip .ballDel.norun {
  background-color:#5a5a5a;
}
#ttip .ballDel.wicket {
  border-radius:0;
}





#tes2024 {
  .winpredict {
    border: 5px solid #3c376f;
    border-radius: 25px;
    background-color: #af1f23;
    font-family: 'Roboto';
    font-weight:900;
    font-size: 36px;
    color: #fff;
  }
  .winpredict .line {
    display: flex;
    opacity:0;
    animation-delay: 0.5s;
  }
  .winpredict .c_ball {
    max-height:30px;
    animation: rotate 5s infinite linear;
  }
  .winpredict .calc {
    font-size:24px;
    padding-left:10px;
    position:relative;
    top:-8px;
  }
  .winpredict .progress {
    height: 40px;
    margin: 30px;
  }
  .windpredict .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
    background-color:#ee732b;
  }
  .sponsor {
    text-align: center;
    width:100%;
    height:100px;
    margin-bottom:10px;
  }
  .teamwin {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 72px;
  }
  .teamname {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;

  }
}

@keyframes slideUpBackground {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideUpLines {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
